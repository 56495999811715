<script>
//   import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";
// import Switches from "vue-switches";
export default {
    // components:{Multiselect},
    props:['service','ODS','send','pos','estimated','disable','planB'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:"",qte:0,amount:0}],
            fieldsD:[],
            selectedService:[{service:"",qte:0,amount:0}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedMaterialsD:[],
            PostesList:[],
            PostesListOrdering:[],
            EQPListOrdering:[],
            ProductsList:[],
            EquipmentsList:[],
            MaterialsList:[],
            RH:[],
            postes:[],
            retrieveRH:false,
            retrieveEQP:false,
            AmountEquipments:0,
            AmountPostes:0,
        }
    },
    
    mounted(){
            if(this.service.quantity > 0){
                this.selectedService = {id:this.service.id,service:this.service.servicable,qty:this.service.quantity,amount:this.service.amount} 
                this.getAmount();
            }
            else this.selectedService = {service:this.service,qty:0,amount:0} 
    },
    methods:{
        selectedPoste(poste,i){
            // console.log(this.fields);
            this.fields[i].poste = poste;
            this.fields[i].qte = parseInt(poste.contractual_qty);
            this.getAmount();
        },
        AddformData() {
            this.fields.push({selctedPoste:"",qte:"",amount:0});
        },
        AddformDataProduct() {
            this.selectedProducts.push({product:"",qte:"",amount:0});
        },
        AddformDataMat() {
            this.selectedMaterials.push({equipment:"",qte:"",amount:0});
        },
        deleteRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fields.splice(index, 1);
                }
            })
        },
        deleteProductRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedProducts.splice(index, 1);
                }
            })
        },
        deleteMatRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedMaterials.splice(index, 1);
                }
            })
            
        },
        getAmount(){
                //  this.selectedProducts.forEach(element => {
                    // console.log(this.selectedService.service.unit_price);
                     this.selectedService.amount = parseInt(this.selectedService.service.unit_price) * parseInt(this.selectedService.qty)
                //  })
                //  console.log(this.selectedProducts);
                 this.TotalAmount()
        },
        TotalAmount(){
            // this.getAmount();
            var total = 0;
                // console.log(this.selectedService.service.unit_price);
                 if(this.selectedService.service) total = parseInt(this.selectedService.qty) * parseInt(this.selectedService.service.unit_price);

                if(total > 0){
                    this.selectedService.amount = total;
                    this.$emit('getvalues',this.selectedService);
                    this.$emit('getvaluesEmitted',true);
                }
                else total = 0;
            
            return total;
        },
        customLabel({name}){
            return `${name}` 
        },
        customLabelEQP({equipments}){
            return `${equipments.designation}` ;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                // this.getAmount();
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="`accordion-${pos+1}`" style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{selectedService.service.name}}</div>
                            <div class="col text-right">
                                <span>Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(TotalAmount())}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse :id="`accordion-${pos+1}`" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg" style="heigth:200px">
                            <div class="row">
                                <!-- <div class="col-md-4">
                                    <label for="">Liste Produits</label>
                                    
                                    <multiselect class="here" v-model="selectedProduct.product" openDirection="below" :maxHeight="500" :options="ProductsList" :multiple="false" :group-select="false" placeholder="Type to search" track-by="id" label="name">
                                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                        <template slot="singleLabel" slot-scope="{ product }"><strong>{{ product.products.name }}</strong></template>
                                    </multiselect>
                                </div> -->
                                <div class="col-md-2" v-if="selectedService.service">
                                    <label for="">Quantité Prévisionnel</label>
                                    <input class="form-control" type="number" disabled  v-model="selectedService.service.estimated_qty">
                                </div>
                                <div class="col-md-2" v-if="selectedService.service">
                                    <label for="">Unité</label>
                                    <input class="form-control" type="text" disabled  v-model="selectedService.service.uom.designation">
                                </div>
                                <div class="col-md-2">
                                    <label for="">Quantité Demandé</label>
                                    <input class="form-control" type="number" placeholder="20" v-model="selectedService.qty"  @keypress="isNumber($event)" @input="getAmount">
                                </div>
                                <!-- <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteProductRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div> -->
                            </div>
                            <!-- <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-info" type="button" @click="AddformDataProduct"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                </div>
                            </div> -->
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>
<style>
.here .multiselect__content-wrapper{
    position:relative !important;
    max-height: 300px !important;
}
</style>